<template>
  <div :class="headerClasses">
    <header>
      <div :class="desktopHeaderClasses">
        <ZNavbar toggler-breakpoint="xLarge">
          <ZNavbarBrand
            :to="localePath({ name: 'index' })"
            @click="navExpanded = false"
          >
            <LogoRvezy class="rvezy-logo" />
          </ZNavbarBrand>

          <ZNavbarNav
            v-if="layoutParams.showOwnerNavbar"
            class="ml-auto"
          >
            <LazyAppNavbarOwner />
          </ZNavbarNav>
          <template v-else>
            <div
              v-if="!layoutParams.hideSearchForm"
              class="d-none d-lg-block search-form-container"
            >
              <Transition name="fade">
                <LazySearchFormSkeleton
                  v-if="isLoading"
                  key="skeleton"
                  :class="desktopSearchFormClasses"
                />
                <LazySearchForm
                  v-else
                  key="content"
                  search-on-change
                  :class="['mr-auto', 'search-desktop', desktopSearchFormClasses]"
                />
              </Transition>
            </div>

            <template v-if="!layoutParams.hideNavigation">
              <ZNavbarToggle @toggle="navExpanded = !navExpanded">
                <Fa
                  v-if="navExpanded"
                  :icon="['far', 'xmark']"
                />
                <template v-else>
                  <Fa :icon="['far', 'bars']" />
                  <span
                    v-if="showMyRVezyPushNote || inboxNotificationsCount"
                    class="push-note"
                  />
                </template>
              </ZNavbarToggle>

              <template v-if="!isXlargeBreakpoint">
                <LazyZNavbarCollapse :show="navExpanded">
                  <AppHeaderNavMobile
                    :dashboard-badge="showMyRVezyPushNote"
                    :inbox-badge="inboxNotificationsCount"
                    class="d-xl-none"
                    @close-nav="navExpanded = false"
                  />
                </LazyZNavbarCollapse>
              </template>
              <template v-else>
                <AppHeaderNavDesktop
                  class="d-none d-xl-flex"
                  :dashboard-badge="showMyRVezyPushNote"
                  :inbox-badge="inboxNotificationsCount"
                />
              </template>
            </template>
          </template>
        </ZNavbar>
      </div>

      <div
        v-if="isDashboardPage && !layoutParams.hideNavigation"
        class="dashboard-navbar-wrapper d-none d-lg-block"
        data-testid="dashboard-navbar"
      >
        <LazyZContainer fluid>
          <ZNavbar class="py-0">
            <AppDashboardNavbar class="ml-auto" />
          </ZNavbar>
        </LazyZContainer>
      </div>
    </header>

    <div
      v-if="showMobileSearchForm"
      :class="mobileSearchFormClasses"
    >
      <div class="d-flex w-100 align-items-center justify-content-between">
        <SearchMobileFormToggle class="flex-grow-1 width-0" />
        <SearchMobileFiltersToggle
          v-if="isRvSearchPage"
          class="ml-2"
        />
      </div>

      <SearchQuickFilters
        v-if="isRvSearchPage"
        class="mt-2 d-lg-none"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useScrollLock } from '@vueuse/core'
import { handleExitIntent } from '~/lib/exit-intent'
import type { NuxtError } from '#app'

const { $captureError, $rentals } = useNuxtApp()
const { logCustomEvent } = await useBraze()
const { user, isLoggedIn } = useAuthentication()
const localePath = useLocalePath()
const { layoutParams } = useLayout()
const {
  isDashboardPage,
  isHomePage,
  isRequestToBookFlow,
  isRvRentalsOrChildPage,
  isRvSearchPage,
} = useBaseName()

const { y } = useWindowScroll()
const { hydrationStatus } = useHydrationState()
const isScrolling = computed(() => y.value > 0 && hydrationStatus.value)
const { isXlargeBreakpoint } = useBreakpoint()

const hasRVezyNotifications = ref(false)
const inboxNotificationsCount = ref(0)
const isLoading = ref(true)

onMounted(() => {
  isLoading.value = false

  handleExitIntent(isLoggedIn.value, logCustomEvent)
})

const showMyRVezyPushNote = computed(() => {
  return hasRVezyNotifications.value || !user.value?.PhoneNumber
})

const showHeaderSearchForm = useShowHeaderSearchForm()

const showLandingPageSearchForm = computed(() => {
  if (isRvRentalsOrChildPage.value) {
    return !showHeaderSearchForm.value
  }

  return isHomePage.value
})

const showMobileSearchForm = computed(() => {
  return !layoutParams.value.hideSearchForm && !isRequestToBookFlow.value
})

watch(
  isLoggedIn,
  async (newVal, oldVal) => {
    if (newVal || typeof oldVal !== 'undefined') {
      await checkUserPendingNotifications()
    }
    else if (!newVal) {
      resetUserPendingNotifications()
    }
  },
  { immediate: true },
)

const navExpanded = ref(false)
const bodyScrollLocked = useScrollLock(document)
watch(
  navExpanded,
  (newVal) => {
    if (import.meta.server) {
      return
    }

    if (newVal) {
      bodyScrollLocked.value = true
    }
    else {
      bodyScrollLocked.value = false
    }
  },
  { immediate: true },
)

async function checkUserPendingNotifications() {
  try {
    if (!isLoggedIn.value) {
      resetUserPendingNotifications()
      return
    }

    const { HasRVezyNotifications, TotalInboxNotifications } = await $rentals('/api/Message/user-pending-notifications-indicator')
    hasRVezyNotifications.value = HasRVezyNotifications
    inboxNotificationsCount.value = TotalInboxNotifications
  }
  catch (error) {
    const knownError = error as NuxtError
    if (knownError.statusCode !== 401) {
      $captureError(error)
    }
    // Fail Silently when not authenticated
  }
}

function resetUserPendingNotifications() {
  hasRVezyNotifications.value = false
  inboxNotificationsCount.value = 0
}

/* Computed Classes */
const headerClasses = computed(() => [
  'header',
  {
    'floating-form': showLandingPageSearchForm.value,
    'is-scrolling': isScrolling.value,
    'search': isRvSearchPage.value,
  },
])

const desktopHeaderClasses = computed(() => [
  'app-header',
  'px-3',
  { fixed: navExpanded.value },
])

const desktopSearchFormClasses = computed(() =>
  [
    {
      'no-opacity': !showHeaderSearchForm.value && showLandingPageSearchForm.value,
    },
  ],
)

const mobileSearchFormClasses = computed(() => [
  'd-lg-none',
  'mx-3',
  'search-mobile',
  {
    'with-height': showHeaderSearchForm.value && showLandingPageSearchForm.value,
    'pb-2': !isRvSearchPage.value,
  },
])
</script>

<style lang="scss" scoped>
.header {
  .search-form-container {
    position: relative;
    width: 27.5rem;
    height: 3.375rem;
    margin-right: auto;
  }

  @include media-max-size(large) {
    .rvezy-logo {
      width: 4.25rem;
      height: 2.125rem;
    }
  }

  :deep(.znavbar) {
    padding: 0.5rem 0;

    .znav-item {
      > a {
        padding-left: 0.875rem;
        padding-right: 0.875rem;
      }

      &.zdropdown {
        .zdropdown-menu {
          border-color: getColor('highlight-100');

          .zdropdown-item {
            color: getColor('primary-500');
            padding: 0.25rem 1.25rem;

            &:hover {
              background-color: getColor('highlight-50');
            }
          }
        }
      }
    }
  }

  .znavbar-toggler {
    border: none;
    width: 2.75rem;
    position: relative;
  }

  @include media-max-size(large) {
    &.floating-form {
      > header {
        z-index: 1;
      }

      .search-mobile {
        transition: top ease-in-out 200ms;
        position: absolute;
        left: -1rem;
        right: -1rem;
        background-color: #fff;
        padding-inline: 1rem;
        z-index: 0;
        top: -350%;
        display: flex;

        &.with-height {
          top: 100%;
        }
      }
    }
  }
}
.search-desktop {
  transition: opacity ease-in-out 200ms;
  opacity: 1;

  &.no-opacity {
    opacity: 0;
    z-index: -1;
  }
}

.dashboard-navbar-wrapper {
  border-top: 1px solid getColor('primary-100');
  border-bottom: 1px solid getColor('primary-100');
}

.is-scrolling {
  .app-header {
    @include media-max-size(large) {
      display: none;
    }
  }

  .search-mobile {
    padding-top: 1rem !important;
  }
}

.app-header.fixed {
  @include media-max-size(xLarge) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $headerZindex;
    overflow-y: auto;
    background-color: #fff;
  }
}

.width-0 {
  width: 0;
}
</style>
